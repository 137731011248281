import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnInit,
  Renderer2,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import {ApiService} from 'src/app/services/api.service';
import {AbstractControl, FormBuilder, FormControl, FormGroup, ValidatorFn, Validators} from '@angular/forms';
import {MustMatch} from "../../validations/password-validator.component";
import {Meta, Title, DomSanitizer, SafeUrl} from "@angular/platform-browser";
import {MetaTags} from "../../constants/meta-data/meta.tags";
import {Router} from "@angular/router";
import {CookieService} from "ngx-cookie-service";

@Component({
  selector: 'app-connectivity',
  templateUrl: './connectivity.component.html',
  styleUrls: ['./connectivity.component.scss']
})
export class ConnectivityComponent implements OnInit {
  @ViewChild('addressInput') addressInput!: ElementRef;
  @ViewChild('addressSection') addressSection!: ElementRef;
  @ViewChild('locationResults', {read: ViewContainerRef}) locationResults!: ViewContainerRef;

  private readonly INVALID_AFTER_SUBMIT = 'INVALID_AFTER_SUBMIT';

  showMapSection: boolean = false
  showChoosePackageSection: boolean = false
  showCreateAccountSection: boolean = false
  showCompleteProfileSection: boolean = false
  showFibreInstallationSection: boolean = false
  orderCompleted: boolean = false
  showNoFibreCoverageSection: boolean = false

  //errors;
  noPackageSelected = false;
  invalidAccountDetails = false;
  invalidProfile = false;
  duplicateUserExist = false;
  invalidLogins = false;

  //googlemaps
  autocomplete!: google.maps.places.Autocomplete
  providers = [
    {
      id: 1,
      imgUrl: '/assets/images/metrofibre-logo-new.svg'
    },
    {
      id: 2,
      imgUrl: '/assets/images/LinkAfrica.svg'
    },
    {
      id: 3,
      imgUrl: '/assets/images/Frogfoot-logo.png'
    },
    {
      id: 4,
      imgUrl: '/assets/images/openserve.png'
    },
    {
      id: 5,
      imgUrl: '/assets/images/Octotel-Logo.svg'
    }
  ]
  mapCenter: any = {lat: 0, lng: 0}
  markerPosition: any = {lat: 0, lng: 0}
  mapStyles = [
    {
      featureType: "poi",
      elementType: "labels",
      stylers: [
        {visibility: "off"}
      ]
    }
  ]
  isLocationSelected: boolean = false
  packageData: any = []
  defaultPackageData: any = []
  providerOptionSelected: any = null;
  selectedProviderPackageData: any = []
  checkCoverageCoords: any = {}
  coveragePackageData: any = []
  order: any = {};
  public sectionBannerImageUrls: SafeUrl[] = [];
  public sectionData: SectionData[] = [];
  public sectionBrochureUrls: string[] = [];
  public isDataLoadedWithoutError: boolean = false;

  public registerForm: FormGroup = new FormGroup({});
  public loginForm: FormGroup = new FormGroup({});
  isSignUpNewAccount = false;
  private cookieDataLoaded = false;
  private readonly emailPattern = /^[a-zA-Z]+([a-zA-Z0-9._-](?![._-])){0,63}[a-zA-Z0-9]+@[a-zA-Z]([a-zA-Z0-9-]*[a-zA-Z0-9])?(\.[a-zA-Z]{2,})+$/;
  private emailValidator = Validators.pattern(this.emailPattern);

  constructor(
    public formBuilder: FormBuilder,
    public ref: ChangeDetectorRef,
    private renderer: Renderer2,
    private apiService: ApiService,
    private cdr: ChangeDetectorRef,
    private meta: Meta,
    private title: Title,
    private sanitizer: DomSanitizer,
    private router: Router,
    private cookieService: CookieService
  ) {

    this.meta.addTags(MetaTags.CONNECTIVITY_TAGS);
    this.setTitle('Business Internet Fibre | Apprentice Valley Digital');
  }

  public setTitle(newTitle: string) {
    this.title.setTitle(newTitle);
  }

  ngOnInit(): void {

    const pageId = 4; // Replace with the actual pageId
    this.fetchSectionData(pageId, 1); // Section 1
    this.fetchSectionData(pageId, 2); // Section 2
    this.fetchSectionData(pageId, 3); // Section 3
    this.fetchSectionData(pageId, 4); // Section 3
    this.fetchSectionData(pageId, 5); // Section 3
    this.fetchSectionData(pageId, 6); // Section 3

    this.apiService.getAllProviders().subscribe((response: any) => {
      this.providers = response.map((data: any) => {
        return {"id": data.id, imgUrl: data.image};
      })
    });

    this.registerForm = this.formBuilder.group(
      {
        id: [""],
        email: ["", [Validators.required, Validators.pattern(this.emailPattern)]],
        password: [""],
        confirmPassword: ["", Validators.required],
        firstName: new FormControl('', [Validators.required, this.validateName()]),
        lastName: new FormControl('', [Validators.required, this.validateName()]),
        contactNumber: new FormControl('', [Validators.required, this.validatePhoneNumber()]),
        accountType: new FormControl('customer_account', [Validators.required]),
        address: new FormGroup({
          unitNumber: new FormControl('', Validators.required),
          complexName: new FormControl(''),
          addressType: new FormControl('', Validators.required),
          address: new FormControl(''),
          orderType: new FormControl('New Installation'),
          latitude: new FormControl(''),
          longitude: new FormControl(''),
          street: new FormControl(''),
          city: new FormControl(''),
          province: new FormControl(''),
          postalCode: new FormControl(''),
          country: new FormControl(''),
        }),
        legalReference: new FormGroup({
          type: new FormControl('RSA_ID', Validators.required),
          value: new FormControl('', Validators.required)
        })
      },
      {
        validator: MustMatch("password", "confirmPassword")
      }
    );

    this.loginForm = this.formBuilder.group({
      email: ["", [Validators.required, Validators.email]],
      password: ["", [Validators.required]]
    });

    setTimeout(() => {
      this.autocomplete = new google.maps.places.Autocomplete(this.addressInput.nativeElement, {fields: ['geometry', 'address_components', 'name']});
      this.autocomplete.addListener('place_changed', this.onPlaceChanged.bind(this));
    }, 2000);
    this.apiService.getDealsData().subscribe((res) => {
      this.packageData = res
      this.defaultPackageData = this.packageData.filter((element: any) => {
        return element.providerId == 4
      })


    })

    this.initiateOrderForm();
  }

  onPlaceChanged() {

    const place = this.autocomplete.getPlace();
    this.locationResults.clear();
    this.addLocationResult(place);

  }

  private validatePhoneNumber(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      const phoneNumber = control.value;
      const phonePattern = /^(\+27|0|27)\d{9}$/;

      if (!phonePattern.test(phoneNumber)) {
        return { invalidPhoneNumber: true };
      }

      return null;
    };
  }

  addLocationResult(place: google.maps.places.PlaceResult) {
    this.showMapSection = true
    this.checkCoverageCoords = {lat: undefined, lng: undefined}
    this.registerForm.get('address.latitude')?.setValue(String(place.geometry?.location?.lat()));
    this.registerForm.get('address.longitude')?.setValue(String(place.geometry?.location?.lng()));
    this.fillInAddress(place, this.registerForm)
    const coords = {lat: place.geometry?.location?.lat(), lng: place.geometry?.location?.lng()}
    this.checkCoverageCoords = coords;
    this.mapCenter.lat = this.checkCoverageCoords.lat;
    this.mapCenter.lng = this.checkCoverageCoords.lng;
    this.markerPosition.lat = this.checkCoverageCoords.lat;
    this.markerPosition.lng = this.checkCoverageCoords.lng;
    this.cdr.detectChanges()
    const el = this.renderer.createElement('div');
    el.innerText = place.formatted_address;
    this.locationResults.insert(el);

  }

  fillInAddress(place: google.maps.places.PlaceResult, formGroup: FormGroup) {
    // Get the place details from the autocomplete object.
    let address1 = "";
    let postcode = "";

    for (const component of place.address_components as google.maps.GeocoderAddressComponent[]) {
      // @ts-ignore remove once typings fixed
      const componentType = component.types[0];

      switch (componentType) {
        case "street_number": {
          address1 = `${component.long_name} `;
          break;
        }

        case "route": {
          address1 += component.short_name;
          formGroup.get('address.street')?.setValue(address1);
          break;
        }

        case "sublocality_level_1": {
          address1 += ', ' + component.long_name;
          break;
        }

        case "locality": {
          address1 += ', ' + component.long_name;
          formGroup.get('address.city')?.setValue(component.long_name);
          break;
        }

        case "administrative_area_level_1": {
          address1 += ', ' + component.long_name;
          formGroup.get('address.province')?.setValue(component.long_name);
          break;
        }

        case "postal_code": {
          postcode = `${component.long_name}${postcode}`;
          address1 += ', ' + component.long_name;
          formGroup.get('address.postcode')?.setValue(component.long_name);
          break;
        }

        case "postal_code_suffix": {
          postcode = `${postcode}-${component.long_name}`;
          break;
        }

        case "country":
          address1 += ', ' + component.long_name;
          formGroup.get('address.country')?.setValue(component.long_name);
          break;
      }
    }
    console.log('address ', address1);
    formGroup.get('address.address')?.setValue(address1)
    console.log('formGroup ', formGroup.value);

  }

  filterPackage(id: number) {
    this.defaultPackageData = this.packageData.filter((element: any) => {
      return element.providerId == id
    })
  }

  selectedProvider(provider: any) {
    console.log("selected ", provider)

    this.providerOptionSelected = provider;
    this.apiService.retrieveProvider(provider.id).subscribe(data => {
      this.selectedProviderPackageData = data;
      this.ref.detectChanges();
      console.log("data ", data)
    })

  }

  locationSelected() {
    this.showMapSection = true;
    console.log(this.checkCoverageCoords);


    this.apiService.getPackageData(this.checkCoverageCoords).subscribe((res) => {
      this.coveragePackageData = res
      console.log(this.coveragePackageData);
    })
  }

  onPackageSelect(solutionId: number) {

    this.order.productSolutionId = solutionId
    const solutionsContainer: any = document.querySelectorAll('.packages-container')
    const selectedElement: any = document.getElementById(`package${solutionId}`)
    if (!selectedElement.classList.contains('active-package')) {
      for (const packageElement of solutionsContainer) {
        packageElement.classList.remove('active-package')
      }
      selectedElement.classList.add('active-package')
    } else {
      selectedElement.classList.remove('active-package')
      this.order.productSolutionId = null
    }
  }

  onProviderSelected(providerSelected: number) {

    const solutionsContainer: any = document.querySelectorAll('.business-img')
    const selectedElement: any = document.getElementById(`business-img-${providerSelected}`)
    for (const packageElement of solutionsContainer) {
      packageElement.classList.remove('active-provider')
    }
    selectedElement.classList.add('active-provider')
  }

  checkCoverage() {
    this.showMapSection = false
    // API request
    this.showChoosePackageSection = true;
    this.ref.detectChanges();
    this.setPersistentCookies(this.registerForm.value);
  }

  packageSelected() {
    if (this.order?.productSolutionId) {
      this.showCreateAccountSection = true
      this.showChoosePackageSection = false
      this.noPackageSelected = false;
    } else {
      this.noPackageSelected = true;
    }
    this.ref.detectChanges();
  }

  createAccount(fields: string[]) {
    this.isSignUpNewAccount = false;
    this.onAnonSignup(fields)
    this.ref.detectChanges();
    this.setPersistentCookies(this.registerForm.value);
  }

  updateAccountDetails(fields: string[]) {
    this.onAnonSignup(fields)
    this.ref.detectChanges();
    this.setPersistentCookies(this.registerForm.value);
  }

  completePersonalInfo(fields: string[]) {
    const noneValid = fields.filter(field => this.isInvalidControl(field, this.getSignUpForm()));
    if (noneValid.length === 0) {
      this.showFibreInstallationSection = true
      this.showCompleteProfileSection = false;
    }
    this.ref.detectChanges();
  }

  completeAddressDetails(fields: string[]) {
    const noneValid = fields.filter(field => {
      console.log(`${field} value ${this.registerForm.get(`address.${field}`)?.value}`)
      console.log(`${field} invalid ${this.registerForm.get(`address.${field}`)?.invalid}`)
      console.log(`${field} dirty ${this.registerForm.get(`address.${field}`)?.dirty}`)
      console.log(`${field} errors ${this.registerForm.get(`address.${field}`)?.errors}`)
      return this.registerForm.get(`address.${field}`)?.invalid || this.registerForm.get(`address.${field}`)?.errors !== null;
    });
    noneValid.forEach(field => {
      if (!this.registerForm.get(`address.${field}`)?.value) {
        this.registerForm.get(`address.${field}`)?.markAsDirty();
        this.registerForm.get(`address.${field}`)?.markAsTouched();
      }
    })
    console.log('noneValid ', noneValid);
    if (noneValid.length === 0) {
      this.showFibreInstallationSection = false;
      this.apiService.createAnonUser(this.registerForm.value).subscribe((data: any) => {
        this.createNewOrder(data);
      });
      this.setPersistentCookies(this.registerForm.value);
    }
    this.ref.detectChanges();
  }

  private createNewOrder(data: any) {
    console.log('response: ', data);
    // create order
    this.order = {
      orderType: this.registerForm.get('address.orderType')?.value,
      productSolutionId: this.order.productSolutionId,
      lead: {
        leadType: 'website',
        type: 'coverage-check'
      },
      consent: {
        type: 'Terms and condition'
      }
    }
    this.apiService.createOrder(data?.id, this.order).subscribe(orderResponse => {
      console.log('orderResponse: ', orderResponse);
      this.orderCompleted = true;
      this.resetModal();
      this.ref.detectChanges();
    });
  }

  resetModal() {
    this.showMapSection = false;
    this.showChoosePackageSection = false;
    this.showCreateAccountSection = false
    this.showCompleteProfileSection = false
    this.showFibreInstallationSection = false
  }

  resetAllModals() {
    this.orderCompleted = false;
    this.resetModal();
  }

  onSubmit() {
    console.log(this.registerForm.value);
    this.ref.detectChanges();
  }


  defaultDownloadBrochure() {
    this.apiService.downloadFile('Connectivity_Solutions.pdf').subscribe();
  }

  downloadBrochure(sectionId: number) {
    const brochureUrl = this.sectionBrochureUrls[sectionId];
    if (brochureUrl) {
      const downloadLink = document.createElement('a');
      downloadLink.href = brochureUrl;
      downloadLink.download = 'brochure.pdf';
      downloadLink.click();
    } else {
      console.error('No brochure URL available for the selected section.');
    }
  }


  private fetchSectionData(pageId: number, sectionId: number) {
    this.apiService.getSectionById(pageId, sectionId).subscribe(
      (data: SectionData) => {
        this.sectionData[sectionId] = data; // Use sectionId as the index
        this.sectionBrochureUrls[sectionId] = data.brochureUrl; // Use sectionId as the index
        this.isDataLoadedWithoutError = true;
      },
      (error) => {
        console.error('Error fetching section data:', error);
        this.isDataLoadedWithoutError = false;
      }
    );
    this.apiService.getBannerImageData(pageId, sectionId).subscribe(
      (imageData: Blob) => {
        const objectURL = URL.createObjectURL(imageData);
        this.setBannerImageUrl(sectionId, objectURL);
      },
      (error) => {
        console.error('Error fetching banner image data:', error);
        this.isDataLoadedWithoutError = false;
      }
    );
  }

  private setBannerImageUrl(sectionId: number, objectURL: string) {
    const safeUrl = this.sanitizer.bypassSecurityTrustUrl(objectURL);
    this.sectionBannerImageUrls[sectionId] = safeUrl; // Use sectionId as the index
  }

  private initiateOrderForm() {
    const orderForm = new FormGroup({
      orderType: new FormControl(''),
      status: new FormControl(''),
      productSolutionId: new FormControl(''),
      lead: new FormGroup({
        leadType: new FormControl('website'),
        type: new FormControl('coverage-check')
      }),
      consent: new FormGroup({
        type: new FormControl('Terms and condition')
      })
    });
  }

  signUpNewAccount() {
    this.isSignUpNewAccount = true;
    this.ref.detectChanges();
  }

  signInAccount() {
    this.isSignUpNewAccount = false;
    this.ref.detectChanges();
  }

  getSignInForm() {
    return this.loginForm.controls;
  }

  getSignUpForm() {
    return this.registerForm.controls;
  }

  isInvalidControl(field: string, controls: any) {
    console.log(`${field} value ${controls[field]?.value}`)
    console.log(`${field} invalid ${controls[field]?.invalid}`)
    console.log(`${field} dirty ${controls[field]?.dirty}`)
    console.log(`${field} errors ${controls[field]?.errors}`)
    return controls[field]?.invalid && (controls[field]?.dirty || controls[field]?.touched)
  }

  invalidateField(field: string, controls: any) {
    if (!controls[field]?.value) {
      controls[field].markAsDirty();
      controls[field].markAsTouched();
    }
  }

  onLogin() {
    this.invalidLogins = false;
    if (this.loginForm.invalid) {
      this.invalidateField('email', this.getSignInForm());
      this.invalidateField('password', this.getSignInForm());
      this.ref.detectChanges();

      console.log('invalid login form')
      return;
    } else {
      console.log(this.loginForm.value);
      this.apiService.loginUser(this.loginForm.get('email')?.value, this.loginForm.get('password')?.value).subscribe((response: any) => {
        console.log('user login: ', response);
        this.registerForm.get('id')?.setValue(response.id);
        this.registerForm.get('firstName')?.setValue(response.firstName);
        this.registerForm.get('lastName')?.setValue(response.lastName);
        this.registerForm.get('contactNumber')?.setValue(response.contactNumber);
        this.registerForm.get('email')?.setValue(response.email);

        this.registerForm.get('address.unitNumber')?.setValue(response.address?.unitNumber);
        this.registerForm.get('address.complexName')?.setValue(response.address?.complexName);

        this.registerForm.get('legalReference.type')?.setValue(response.legalReference?.type);
        this.registerForm.get('legalReference.value')?.setValue(response.legalReference?.value);
        this.showCompleteProfileSection = true;
        this.showCreateAccountSection = false;
        this.ref.detectChanges();
      }, (error) => {
        // Handle the error here
        console.error('An error occurred:', error);
        this.invalidLogins = true;
        // You can also set an error flag or display an error message to the user.
      })
    }
  }

  onAnonSignup(fields: string[]) {
    if (this.registerForm.invalid) {
      fields.forEach(field => {
        this.invalidateField(field, this.getSignUpForm());
      });

      const noneValid = fields.filter(field => this.isInvalidControl(field, this.getSignUpForm()));

      console.log('invalid signup form');
      if (this.showCreateAccountSection && noneValid.length === 0) {
        this.showCompleteProfileSection = true;
        this.showCreateAccountSection = false;
        console.log("moving on to the next section ", fields)
      }

      this.ref.detectChanges();
      return;
    }
  }

  onSignUp(fields: string[]) {
    this.duplicateUserExist = false;
    this.apiService.isUser(this.registerForm.get('email')?.value).subscribe((response: any) => {
        if (!response) {
          if (this.registerForm.invalid) {
            fields.forEach(field => {
              this.invalidateField(field, this.getSignUpForm());
            });

            const noneValid = fields.filter(field => this.isInvalidControl(field, this.getSignUpForm()));

            console.log('invalid signup form');
            if (this.showCreateAccountSection && noneValid.length === 0) {
              this.showCompleteProfileSection = true;
              this.showCreateAccountSection = false;
              console.log("moving on to the next section ", fields)
            }

            this.ref.detectChanges();
            return;
          } else {

            this.showCompleteProfileSection = true;
            this.showCreateAccountSection = false;
            console.log(this.registerForm.value);
          }
        } else {
          this.duplicateUserExist = true
        }
        this.ref.detectChanges();
      },
      (error) => {
        // Handle the error here
        console.error('An error occurred:', error);
        this.duplicateUserExist = true;
        // You can also set an error flag or display an error message to the user.
      })
  }

  isPasswordMatch() {
    return this.getSignUpForm()['confirmPassword'].errors == null;
  }

  setIdentificationType(type: string) {
    this.registerForm.get('legalReference.type')?.setValue(type);
  }

  goToCheckCoverage() {
    // /home#contact
    this.addressSection.nativeElement.scrollIntoView({behavior: 'smooth'});
  }

  openContactPage() {
    this.router.navigate(['/home'], { fragment: 'contact' });
  }
  patchRegisterFormWithCookies(): void {
    if (this.cookieDataLoaded) {
      return;
    }

    const cookieKeys = this.cookieService.getAll();
    const excludedFields = ['address.orderType', 'address.addressType'];

    Object.keys(cookieKeys).forEach(key => {
      if (key.includes('.')) {
        const keyParts = key.split('.');
        let formGroup = this.registerForm;
        for (let i = 0; i < keyParts.length - 1; i++) {
          formGroup = formGroup.get(keyParts[i]) as FormGroup;
        }
        const fieldPath = keyParts.join('.');
        if (!excludedFields.includes(fieldPath)) {
          const cookieValue = cookieKeys[key];
          if (cookieValue && formGroup.get(keyParts[keyParts.length - 1])) {
            const control = formGroup.get(keyParts[keyParts.length - 1]);
            if (control && (!control.value || control.value === '')) {
              control.patchValue(cookieValue);
            }
          }
        }
      } else {
        if (!excludedFields.includes(key)) {
          const cookieValue = cookieKeys[key];
          if (cookieValue && this.registerForm.get(key)) {
            const control = this.registerForm.get(key);
            if (control && (!control.value || control.value === '')) {
              control.patchValue(cookieValue);
            }
          }
        }
      }
    });

    this.cookieDataLoaded = true;
  }
  private setPersistentCookies(formData: any) {
    const cookieMap = {};
    this.traverse(formData, '', cookieMap);

    const expirationDate = new Date();
    expirationDate.setFullYear(expirationDate.getFullYear() + 1);

    Object.entries(cookieMap).forEach(([key, value]) => {
      const valueAsString = typeof value === "number" && key !== 'id' ? value.toString() : value;
      if (typeof valueAsString === "string") {
        this.cookieService.set(key, valueAsString, expirationDate, undefined, undefined, true);
      }
    });
  }

  private traverse(data: any, prefix: string, cookieMap: any): void {
    for (const [key, value] of Object.entries(data)) {
      const newKey = prefix ? `${prefix}.${key}` : key;
      if (typeof value === 'object' && value !== null) {
        this.traverse(value, newKey, cookieMap);
      } else {
        cookieMap[newKey] = value;
      }
    }
  }
  private validateName(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      const name = control.value?.trim();
      const namePattern = /^(?!.*[-'\s]{2})[A-Za-z]+(?:[ '-][A-Za-z]+)*$/;

      if (!namePattern.test(name)) {
        return { invalidName: true };
      }

      return null;
    };
  }

  getErrorMessage(controlName: string): string {
    const control = this.registerForm.get(controlName);
    if (control?.hasError('required')) {
      return 'This field is required';
    }
    if (control?.hasError('email')) {
      return 'Please enter a valid email address';
    }
    if (control?.hasError('pattern')) {
      if (controlName === 'email') {
        return 'Please enter a valid email address';
      }
    }
    if (control?.hasError('invalidName')) {
      return 'Please enter a valid name (2-50 characters, letters, hyphens, and apostrophes only)';
    }
    if (control?.hasError('invalidPhoneNumber')) {
      return 'Please enter a valid South African phone number (e.g., 0123456789 or +27123456789)';
    }
    return '';
  }
}

interface SectionData {
  sectionId: number;
  pageId: number;
  headerTitle: string;
  headerDescription: string;
  bannerUrl: string;
  brochureUrl: string;
}

