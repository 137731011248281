<ng-container *ngIf="isDataLoadedWithoutError; else defaultContent">
<!-- Header Section Start -->
<header class="smme">
  <nav class="d-none d-md-block">
    <div class="container">
    </div>
  </nav>

  <section id="banner">
    <div class="container text-center">
      <h1 style="white-space: pre-wrap;">{{ sectionData[1]?.headerTitle }}</h1>
      <p class="not_padding d-none d-md-block" style="white-space: pre-wrap;">{{ sectionData[1]?.headerDescription }}</p>
    </div>
  </section>
</header>
<!-- ||Header Section End|| -->

<!-- ads Section Start -->
<section id="ads">
  <div class="container">
    <div class="row">
      <div class="col-md-6">
        <div class="d-flex align-items-center containt">
          <div class="ads_icone">
            <img src="assets/images/glov.png" alt="Mirror Icon" />
          </div>
          <div>
            <h4>Widespread Coverage</h4>
            <p>
              Coverage wherever there is mobile network
              coverage
            </p>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="d-flex align-items-center containt">
          <div class="ads_icone">
            <img src="assets/images/home.png" alt="Home Icon" />
          </div>
          <div>
            <h4>NO INSTALLATION</h4>
            <p>
              No installation required. No copper cables
              to dig up.
            </p>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="d-flex align-items-center containt">
          <div class="ads_icone">
            <img src="assets/images/taka.png" alt="Money Icon" />
          </div>
          <div>
            <h4>Affordable Options</h4>
            <p>
              Packages range from SIM Only to SIM & Router
              options
            </p>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="d-flex align-items-center containt">
          <div class="ads_icone">
            <img src="assets/images/plain.png" alt="Paper plan icon" />
          </div>
          <div>
            <h4>High Speed</h4>
            <p>
              Coverage wherever there is mobile network
              coverage
            </p>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="d-flex align-items-center containt">
          <div class="ads_icone">
            <img src="assets/images/clint.png" alt="Client Icon" />
          </div>
          <div>
            <h4>24/7 Support</h4>
            <p>Our support team is ready to help</p>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="d-flex align-items-center containt">
          <div class="ads_icone">
            <img src="assets/images/wifi.png" alt="Wifi Icon image" />
          </div>
          <div>
            <h4>Stable Streaming speeds</h4>
            <p>
              You can order the SIM Only and use your own
              LTE compatible device
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- ||ads Section End|| -->

<!-- Internet LTE Plans Section Start -->
<section id="ltp-plans">
  <div class="container">
    <div class="ltp_heading text-center text-uppercase">
      <h2>
        Uncapped Business<br />
        <span>Internet LTE plans</span>
      </h2>
    </div>
    <div class="row">
      <div class="col-sm-6 col-md-4">
        <div class="ltp_plans">
          <div class="ltp-img">
            <img
              src="assets/images/router-1.png"
              alt="Image of a router"
              class="w-100"
            />
          </div>
          <div class="ltp_plans_text">
            <h3>BI LTE 10mbps*</h3>
            <p>
              - Plug & play <br />
              -No installation needed <br />
              - A free Wi-Fi router<br />
              -Best suited to use with the latest LTE
              Routers<br />
              -Not Geo Locked to network tower, so you can
              move your LTE Router with you<br />
              -Not suitable for mobile use<br />
            </p>
          </div>
          <div class="ltp_plans_btn">
            <a  (click)="modalService.open('deal-application-journey', {'dealId': 1, lead: lead})"
            >Apply Now <i class="fa fa-solid fa-play"></i
            ></a>
          </div>
        </div>
      </div>
      <div class="col-sm-6 col-md-4 mt-5 mt-sm-0">
        <div class="ltp_plans">
          <div class="ltp-img">
            <img
              src="assets/images/router-1.png"
              alt="Image of a router"
              class="w-100"
            />
          </div>
          <div class="ltp_plans_text">
            <h3>BI LTE 50mbps*</h3>
            <p>
              - Plug & play <br />
              -No installation needed <br />
              - A free Wi-Fi router<br />
              -Best suited to use with the latest LTE
              Routers<br />
              -Not Geo Locked to network tower, so you can
              move your LTE Router with you<br />
              -Not suitable for mobile use<br />
            </p>
          </div>
          <div class="ltp_plans_btn">
            <a  (click)="modalService.open('deal-application-journey', {'dealId': 2, lead: lead})"
            >Apply Now <i class="fa fa-solid fa-play"></i
            ></a>
          </div>
        </div>
      </div>
      <div class="col-sm-6 col-md-4 mt-5 mt-md-0">
        <div class="ltp_plans">
          <div class="ltp-img">
            <img
              src="assets/images/router-1.png"
              alt="Image of a router"
              class="w-100"
            />
          </div>
          <div class="ltp_plans_text">
            <h3>BI LTE 100mbps*</h3>
            <p>
              - Plug & play <br />
              -No installation needed <br />
              - A free Wi-Fi router<br />
              -Best suited to use with the latest LTE
              Routers<br />
              -Not Geo Locked to network tower, so you can
              move your LTE Router with you<br />
              -Not suitable for mobile use<br />
            </p>
          </div>
          <div class="ltp_plans_btn">
            <a  (click)="modalService.open('deal-application-journey', {'dealId': 3, lead: lead})"
            >Apply Now <i class="fa fa-solid fa-play"></i
            ></a>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<!-- LTE Footer -->
<div class="lte_footer container">
  <p class="text-center">
    Fair Use Policy applies to all Uncapped LTE data plans. Speed
    will be throttled once the fair usage has been reached.
  </p>
</div>
<!-- ||Internet LTE Plans Section End|| -->

<!-- LTE Data Plans Section Start -->
<section id="ltp-plans" class="lte-data-plans">
  <div class="container">
    <div class="ltp_heading text-center text-uppercase">
      <h2>
        Business Internet LTE<br />
        BizDay Data Plans
      </h2>
    </div>
    <div class="row">
      <div class="col-sm-6 col-md-4">
        <div class="ltp_plans">
          <div class="ltp_plans_text">
            <h5>LTE 100GB</h5>
            <h6>50GB + 50GB</h6>
            <div class="offer">
              <p>
                - Plug & play<br />
                -Unrestricted Bandwidth<br />
                - A free Wi-Fi router
              </p>
            </div>
            <p>
              Available on 12, 24, & 36<br />
              Months Contract
            </p>
            <div class="plan-price">
              <p>starting from</p>
              <h4>R269PM</h4>
            </div>
          </div>
          <div class="ltp_plans_btn">
            <a  (click)="modalService.open('deal-application-journey', {'dealId': 4, lead: lead})"
            >Apply Now <i class="fa fa-solid fa-play"></i
            ></a>
          </div>
        </div>
      </div>
      <div class="col-sm-6 col-md-4 mt-5 mt-sm-0">
        <div class="ltp_plans">
          <div class="ltp_plans_text">
            <h5>LTE 300GB</h5>
            <h6>150GB + 100GB</h6>
            <div class="offer">
              <p>
                - Plug & play<br />
                -Unrestricted Bandwidth<br />
                - A free Wi-Fi router
              </p>
            </div>
            <p>
              Available on 12, 24, & 36<br />
              Months Contract
            </p>
            <div class="plan-price">
              <p>starting from</p>
              <h4>R299PM</h4>
            </div>
          </div>
          <div class="ltp_plans_btn">
            <a  (click)="modalService.open('deal-application-journey', {'dealId': 5, lead: lead})"
            >Apply Now <i class="fa fa-solid fa-play"></i
            ></a>
          </div>
        </div>
      </div>
      <div class="col-sm-12 col-md-4 mt-5 mt-md-0">
        <div class="ltp_plans">
          <div class="ltp_plans_text">
            <h5>LTE 500GB</h5>
            <h6>300GB + 200GB</h6>
            <div class="offer">
              <p>
                - Plug & play<br />
                -Unrestricted Bandwidth<br />
                - A free Wi-Fi router
              </p>
            </div>
            <p>
              Available on 12, 24, & 36<br />
              Months Contract
            </p>
            <div class="plan-price">
              <p>starting from</p>
              <h4>R599PM</h4>
            </div>
          </div>
          <div class="ltp_plans_btn">
            <a  (click)="modalService.open('deal-application-journey', {'dealId': 6, lead: lead})"
            >Apply Now <i class="fa fa-solid fa-play"></i
            ></a>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- ||LTE Data Plans Section End|| -->

<!-- View More Section Start -->
<section id="view">
  <div class="container">
    <h4 class="text-uppercase">View more details</h4>
  </div>
</section>
<!-- ||View More Section End|| -->

<!-- Price Table Section Start -->
<div id="price-table">
  <section id="internet-plans">
    <div class="plans-container container">
      <table class="text-center">
        <thead>
        <th>Anytime Data</th>
        <th>BizDay Bundle (GB) (8am-5pm)</th>
        <th>Max Bandwidth</th>
        <th>12 Months</th>
        <th>24 Months</th>
        </thead>
        <tbody>
        <tr>
          <td>5GB</td>
          <td>5GB</td>
          <td>Unrestricted</td>
          <td>-</td>
          <td>R499</td>
        </tr>
        <tr>
          <td>10GB</td>
          <td>10GB</td>
          <td>Unrestricted</td>
          <td>-</td>
          <td>R499</td>
        </tr>
        <tr>
          <td>20GB</td>
          <td>20GB</td>
          <td>Unrestricted</td>
          <td>R600</td>
          <td>R499</td>
        </tr>
        <tr>
          <td>50GB</td>
          <td>50GB</td>
          <td>Unrestricted</td>
          <td>R600</td>
          <td>R799</td>
        </tr>
        <tr>
          <td>150GB</td>
          <td>150GB</td>
          <td>Unrestricted</td>
          <td>-</td>
          <td>R499</td>
        </tr>
        <tr>
          <td>200GB</td>
          <td>200GB</td>
          <td>Unrestricted</td>
          <td>-</td>
          <td>R899</td>
        </tr>
        <tr>
          <td>300GB</td>
          <td>300GB</td>
          <td>Unrestricted</td>
          <td>-</td>
          <td>R499</td>
        </tr>
        </tbody>
      </table>
      <div class="plans-buttons">
        <button><a [routerLink]="['/']" fragment="contact">SIGN UP NOW</a></button>
        <button><a href="" class="extra_width">GET VOIP</a></button>
      </div>
    </div>
    <div class="plans_img">
      <img src="assets/images/shadow.png" alt="Apprentice Valley Digital Wave Banner Image" />
      <div class="overlay">
        <p>
          *Prices inclusive of router, SIM and data, VAT
          included.
        </p>
      </div>
    </div>
    <div class="sec-banner">
      <img
        class="w-100"
        src="assets/images/banner-5.jpg"
        alt="sim card and router image banner"
      />
    </div>
    <div class="plans-container container">
      <div class="text-center text-uppercase">
        <h2 class="olter">
          Business Internet LTE Sim only plans
        </h2>
      </div>
      <table class="text-center">
        <thead>
        <th>Product name</th>
        <th>Anytime Data Allocation (GB)</th>
        <th>BizDay Bundle (GB)</th>
        <th>Max Bandwidth</th>
        <th class="d-none d-sm-inline-block">24 Months</th>
        <th class="d-none d-sm-inline-block ps-3">
          36 Months
        </th>
        </thead>
        <tbody>
        <tr>
          <td>Business Internet LTE 100GB</td>
          <td>150GB (was 100GB)</td>
          <td>100</td>
          <td>Unrestricted</td>
          <td class="d-none d-sm-inline-block">R299pm</td>
          <td class="d-none d-sm-inline-block">R249pm</td>
        </tr>
        <tr>
          <td>Business Internet LTE 300GB</td>
          <td>300</td>
          <td>200</td>
          <td>Unrestricted</td>
          <td class="d-none d-sm-inline-block">R599pm</td>
          <td class="d-none d-sm-inline-block">R549pm</td>
        </tr>
        </tbody>
      </table>
    </div>
  </section>
</div>
<!-- ||Price Table Section End|| -->

<!-- Get In Touch Section Start -->
<section id="get-touch">
  <div class="container">
    <div
      class="d-flex justify-content-between align-items-center flex-column-reverse flex-md-row"
    >
      <div class="touch-text">
        <h6 style="white-space: pre-wrap;">{{ sectionData[2]?.headerTitle }}</h6>
        <p style="white-space: pre-wrap;">{{ sectionData[2]?.headerDescription }}</p>
        <br>
        <button class="touch-btn" (click)="openContactPage()">Get In Touch</button>
      </div>
      <div class="touch-img">
        <img src="assets/images/router-1.png" alt="image of a router" />
      </div>
    </div>
  </div>
</section>
<!-- ||Get In Touch Section End|| -->

<!-- Download btn Section Start -->
<div class="container">
  <div class="download_btn">
    <button class="brochure-btn" (click)="downloadBrochure(2)">Download the brochure</button>
  </div>
</div>
<!-- ||Download btn Section End|| -->
<app-deal-application-journey/>

</ng-container>

<ng-template #defaultContent>

  <!-- Header Section Start -->
  <header class="smme">
    <nav class="d-none d-md-block">
      <div class="container">
      </div>
    </nav>

    <section id="banner">
      <div class="container text-center">
        <h1>
          Business grade <br class="d-none d-sm-block" />
          Plug & Play connectivity.<br
          class="d-none d-sm-block"
        />
          LTE & Sim Only internet
        </h1>
        <p class="not_padding d-none d-md-block">
          LTE offers a faster and more efficient data network for
          small- and medium-sized businesses, as well as for
          work-from-home employees, with speeds up to three times
          faster than 3G.
        </p>
        <p>
          At an average speed of 25Mbps, Vodacom Business Internet
          LTE is a fixed service that lets you connect multiple
          devices for easy file sharing and seamless collaboration
          across the office
        </p>
      </div>
    </section>
  </header>
  <!-- ||Header Section End|| -->

  <!-- ads Section Start -->
  <section id="ads">
    <div class="container">
      <div class="row">
        <div class="col-md-6">
          <div class="d-flex align-items-center containt">
            <div class="ads_icone">
              <img src="assets/images/glov.png" alt="Mirror Icon" />
            </div>
            <div>
              <h4>Widespread Coverage</h4>
              <p>
                Coverage wherever there is mobile network
                coverage
              </p>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="d-flex align-items-center containt">
            <div class="ads_icone">
              <img src="assets/images/home.png" alt="Home Icon" />
            </div>
            <div>
              <h4>NO INSTALLATION</h4>
              <p>
                No installation required. No copper cables
                to dig up.
              </p>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="d-flex align-items-center containt">
            <div class="ads_icone">
              <img src="assets/images/taka.png" alt="Money Icon" />
            </div>
            <div>
              <h4>Affordable Options</h4>
              <p>
                Packages range from SIM Only to SIM & Router
                options
              </p>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="d-flex align-items-center containt">
            <div class="ads_icone">
              <img src="assets/images/plain.png" alt="Paper plan icon" />
            </div>
            <div>
              <h4>High Speed</h4>
              <p>
                Coverage wherever there is mobile network
                coverage
              </p>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="d-flex align-items-center containt">
            <div class="ads_icone">
              <img src="assets/images/clint.png" alt="Client Icon" />
            </div>
            <div>
              <h4>24/7 Support</h4>
              <p>Our support team is ready to help</p>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="d-flex align-items-center containt">
            <div class="ads_icone">
              <img src="assets/images/wifi.png" alt="Wifi Icon image" />
            </div>
            <div>
              <h4>Stable Streaming speeds</h4>
              <p>
                You can order the SIM Only and use your own
                LTE compatible device
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- ||ads Section End|| -->

  <!-- Internet LTE Plans Section Start -->
  <section id="ltp-plans">
    <div class="container">
      <div class="ltp_heading text-center text-uppercase">
        <h2>
          Uncapped Business<br />
          <span>Internet LTE plans</span>
        </h2>
      </div>
      <div class="row">
        <div class="col-sm-6 col-md-4">
          <div class="ltp_plans">
            <div class="ltp-img">
              <img
                src="assets/images/router-1.png"
                alt="Image of a router"
                class="w-100"
              />
            </div>
            <div class="ltp_plans_text">
              <h3>BI LTE 10mbps*</h3>
              <p>
                - Plug & play <br />
                -No installation needed <br />
                - A free Wi-Fi router<br />
                -Best suited to use with the latest LTE
                Routers<br />
                -Not Geo Locked to network tower, so you can
                move your LTE Router with you<br />
                -Not suitable for mobile use<br />
              </p>
            </div>
            <div class="ltp_plans_btn">
              <a  (click)="modalService.open('deal-application-journey', {'dealId': 1, lead: lead})"
              >Apply Now <i class="fa fa-solid fa-play"></i
              ></a>
            </div>
          </div>
        </div>
        <div class="col-sm-6 col-md-4 mt-5 mt-sm-0">
          <div class="ltp_plans">
            <div class="ltp-img">
              <img
                src="assets/images/router-1.png"
                alt="Image of a router"
                class="w-100"
              />
            </div>
            <div class="ltp_plans_text">
              <h3>BI LTE 50mbps*</h3>
              <p>
                - Plug & play <br />
                -No installation needed <br />
                - A free Wi-Fi router<br />
                -Best suited to use with the latest LTE
                Routers<br />
                -Not Geo Locked to network tower, so you can
                move your LTE Router with you<br />
                -Not suitable for mobile use<br />
              </p>
            </div>
            <div class="ltp_plans_btn">
              <a  (click)="modalService.open('deal-application-journey', {'dealId': 2, lead: lead})"
              >Apply Now <i class="fa fa-solid fa-play"></i
              ></a>
            </div>
          </div>
        </div>
        <div class="col-sm-6 col-md-4 mt-5 mt-md-0">
          <div class="ltp_plans">
            <div class="ltp-img">
              <img
                src="assets/images/router-1.png"
                alt="Image of a router"
                class="w-100"
              />
            </div>
            <div class="ltp_plans_text">
              <h3>BI LTE 100mbps*</h3>
              <p>
                - Plug & play <br />
                -No installation needed <br />
                - A free Wi-Fi router<br />
                -Best suited to use with the latest LTE
                Routers<br />
                -Not Geo Locked to network tower, so you can
                move your LTE Router with you<br />
                -Not suitable for mobile use<br />
              </p>
            </div>
            <div class="ltp_plans_btn">
              <a  (click)="modalService.open('deal-application-journey', {'dealId': 3, lead: lead})"
              >Apply Now <i class="fa fa-solid fa-play"></i
              ></a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <!-- LTE Footer -->
  <div class="lte_footer container">
    <p class="text-center">
      Fair Use Policy applies to all Uncapped LTE data plans. Speed
      will be throttled once the fair usage has been reached.
    </p>
  </div>
  <!-- ||Internet LTE Plans Section End|| -->

  <!-- LTE Data Plans Section Start -->
  <section id="ltp-plans" class="lte-data-plans">
    <div class="container">
      <div class="ltp_heading text-center text-uppercase">
        <h2>
          Business Internet LTE<br />
          BizDay Data Plans
        </h2>
      </div>
      <div class="row">
        <div class="col-sm-6 col-md-4">
          <div class="ltp_plans">
            <div class="ltp_plans_text">
              <h5>LTE 100GB</h5>
              <h6>50GB + 50GB</h6>
              <div class="offer">
                <p>
                  - Plug & play<br />
                  -Unrestricted Bandwidth<br />
                  - A free Wi-Fi router
                </p>
              </div>
              <p>
                Available on 12, 24, & 36<br />
                Months Contract
              </p>
              <div class="plan-price">
                <p>starting from</p>
                <h4>R269PM</h4>
              </div>
            </div>
            <div class="ltp_plans_btn">
              <a  (click)="modalService.open('deal-application-journey', {'dealId': 4, lead: lead})"
              >Apply Now <i class="fa fa-solid fa-play"></i
              ></a>
            </div>
          </div>
        </div>
        <div class="col-sm-6 col-md-4 mt-5 mt-sm-0">
          <div class="ltp_plans">
            <div class="ltp_plans_text">
              <h5>LTE 300GB</h5>
              <h6>150GB + 100GB</h6>
              <div class="offer">
                <p>
                  - Plug & play<br />
                  -Unrestricted Bandwidth<br />
                  - A free Wi-Fi router
                </p>
              </div>
              <p>
                Available on 12, 24, & 36<br />
                Months Contract
              </p>
              <div class="plan-price">
                <p>starting from</p>
                <h4>R299PM</h4>
              </div>
            </div>
            <div class="ltp_plans_btn">
              <a  (click)="modalService.open('deal-application-journey', {'dealId': 5, lead: lead})"
              >Apply Now <i class="fa fa-solid fa-play"></i
              ></a>
            </div>
          </div>
        </div>
        <div class="col-sm-12 col-md-4 mt-5 mt-md-0">
          <div class="ltp_plans">
            <div class="ltp_plans_text">
              <h5>LTE 500GB</h5>
              <h6>300GB + 200GB</h6>
              <div class="offer">
                <p>
                  - Plug & play<br />
                  -Unrestricted Bandwidth<br />
                  - A free Wi-Fi router
                </p>
              </div>
              <p>
                Available on 12, 24, & 36<br />
                Months Contract
              </p>
              <div class="plan-price">
                <p>starting from</p>
                <h4>R599PM</h4>
              </div>
            </div>
            <div class="ltp_plans_btn">
              <a  (click)="modalService.open('deal-application-journey', {'dealId': 6, lead: lead})"
              >Apply Now <i class="fa fa-solid fa-play"></i
              ></a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- ||LTE Data Plans Section End|| -->

  <!-- View More Section Start -->
  <section id="view">
    <div class="container">
      <h4 class="text-uppercase">View more details</h4>
    </div>
  </section>
  <!-- ||View More Section End|| -->

  <!-- Price Table Section Start -->
  <div id="price-table">
    <section id="internet-plans">
      <div class="plans-container container">
        <table class="text-center">
          <thead>
          <th>Anytime Data</th>
          <th>BizDay Bundle (GB) (8am-5pm)</th>
          <th>Max Bandwidth</th>
          <th>12 Months</th>
          <th>24 Months</th>
          </thead>
          <tbody>
          <tr>
            <td>5GB</td>
            <td>5GB</td>
            <td>Unrestricted</td>
            <td>-</td>
            <td>R499</td>
          </tr>
          <tr>
            <td>10GB</td>
            <td>10GB</td>
            <td>Unrestricted</td>
            <td>-</td>
            <td>R499</td>
          </tr>
          <tr>
            <td>20GB</td>
            <td>20GB</td>
            <td>Unrestricted</td>
            <td>R600</td>
            <td>R499</td>
          </tr>
          <tr>
            <td>50GB</td>
            <td>50GB</td>
            <td>Unrestricted</td>
            <td>R600</td>
            <td>R799</td>
          </tr>
          <tr>
            <td>150GB</td>
            <td>150GB</td>
            <td>Unrestricted</td>
            <td>-</td>
            <td>R499</td>
          </tr>
          <tr>
            <td>200GB</td>
            <td>200GB</td>
            <td>Unrestricted</td>
            <td>-</td>
            <td>R899</td>
          </tr>
          <tr>
            <td>300GB</td>
            <td>300GB</td>
            <td>Unrestricted</td>
            <td>-</td>
            <td>R499</td>
          </tr>
          </tbody>
        </table>
        <div class="plans-buttons">
          <button><a [routerLink]="['/']" fragment="contact">SIGN UP NOW</a></button>
          <button><a href="" class="extra_width">GET VOIP</a></button>
        </div>
      </div>
      <div class="plans_img">
        <img src="assets/images/shadow.png" alt="Apprentice Valley Digital Wave Banner Image" />
        <div class="overlay">
          <p>
            *Prices inclusive of router, SIM and data, VAT
            included.
          </p>
        </div>
      </div>
      <div class="sec-banner">
        <img
          class="w-100"
          src="assets/images/banner-5.jpg"
          alt="sim card and router image banner"
        />
      </div>
      <div class="plans-container container">
        <div class="text-center text-uppercase">
          <h2 class="olter">
            Business Internet LTE Sim only plans
          </h2>
        </div>
        <table class="text-center">
          <thead>
          <th>Product name</th>
          <th>Anytime Data Allocation (GB)</th>
          <th>BizDay Bundle (GB)</th>
          <th>Max Bandwidth</th>
          <th class="d-none d-sm-inline-block">24 Months</th>
          <th class="d-none d-sm-inline-block ps-3">
            36 Months
          </th>
          </thead>
          <tbody>
          <tr>
            <td>Business Internet LTE 100GB</td>
            <td>150GB (was 100GB)</td>
            <td>100</td>
            <td>Unrestricted</td>
            <td class="d-none d-sm-inline-block">R299pm</td>
            <td class="d-none d-sm-inline-block">R249pm</td>
          </tr>
          <tr>
            <td>Business Internet LTE 300GB</td>
            <td>300</td>
            <td>200</td>
            <td>Unrestricted</td>
            <td class="d-none d-sm-inline-block">R599pm</td>
            <td class="d-none d-sm-inline-block">R549pm</td>
          </tr>
          </tbody>
        </table>
      </div>
    </section>
  </div>
  <!-- ||Price Table Section End|| -->

  <!-- Get In Touch Section Start -->
  <section id="get-touch">
    <div class="container">
      <div
        class="d-flex justify-content-between align-items-center flex-column-reverse flex-md-row"
      >
        <div class="touch-text">
          <h6>Get In Touch</h6>
          <p>
            Didn’t find what you were looking for? Use the
            form<br class="d-none d-lg-block" />
            below to get in touch and tell us how we can help<br
            class="d-none d-lg-block"
          />
            you.
          </p>
          <br>
          <button class="touch-btn" (click)="openContactPage()">Get In Touch</button>
        </div>
        <div class="touch-img">
          <img src="assets/images/router-1.png" alt="image of a router" />
        </div>
      </div>
    </div>
  </section>
  <!-- ||Get In Touch Section End|| -->

  <!-- Download btn Section Start -->
  <div class="container">
    <div class="download_btn">
      <button class="brochure-btn" (click)="defaultDownloadBrochure()">Download the brochure</button>
    </div>
  </div>
  <!-- ||Download btn Section End|| -->
  <app-deal-application-journey/>

</ng-template>
