import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormControl, FormGroup, Validators, ValidatorFn, AbstractControl } from "@angular/forms";
import { ApiService } from "../../services/api.service";
import { Meta, Title, DomSanitizer, SafeUrl } from "@angular/platform-browser";
import { MetaTags } from "../../constants/meta-data/meta.tags";
import { CookieService } from "ngx-cookie-service";
import {distinctUntilChanged, filter} from "rxjs";

interface SectionData {
  sectionId: number;
  pageId: number;
  headerTitle: string;
  headerDescription: string;
  bannerUrl: string;
  brochureUrl: string;
}

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  @ViewChild('contact') contactSection!: ElementRef;
  public contactForm: FormGroup = new FormGroup({});
  public submittedQuery: boolean = false;
  public sectionBannerImageUrls: SafeUrl[] = [];
  public sectionData: SectionData[] = [];
  public sectionBrochureUrls: string[] = [];
  public isDataLoadedWithoutError: boolean = false;
  private cookieDataLoaded = false;
  private readonly emailPattern = /^[a-zA-Z]+([a-zA-Z0-9._-](?![._-])){0,63}[a-zA-Z0-9]+@[a-zA-Z]([a-zA-Z0-9-]*[a-zA-Z0-9])?(\.[a-zA-Z]{2,})+$/;
  private emailValidator = Validators.pattern(this.emailPattern);

  constructor(
    private route: ActivatedRoute,
    private apiService: ApiService,
    private meta: Meta,
    private title: Title,
    private sanitizer: DomSanitizer,
    private cookieService: CookieService,
    private activatedRoute: ActivatedRoute
  ) {
    this.meta.addTags(MetaTags.HOME_TAGS);
    this.setTitle('Welcome to Apprentice Valley Digital');
  }

  public setTitle(newTitle: string) {
    this.title.setTitle(newTitle);
  }

  ngOnInit(): void {
    const pageId = 1; //Home page id
    this.fetchSectionData(pageId, 1);
    this.fetchSectionData(pageId, 2);
    this.fetchSectionData(pageId, 3);

    this.contactForm = new FormGroup({
      firstName: new FormControl('', [Validators.required, this.validateName()]),
      email: new FormControl('', [Validators.required, this.emailValidator]),
      accountType: new FormControl('customer_query'),
      contactNumber: new FormControl('', [this.validatePhoneNumber()]),
    });

    this.subscribeToFragment();
  }

  subscribeToFragment() {
    this.activatedRoute.fragment.pipe(
      filter(fragment => !!fragment), // Only proceed if fragment is not null or undefined
      distinctUntilChanged() // Only react if the fragment has changed
    ).subscribe(fragment => {
      console.log(`Attempting to scroll to fragment: ${fragment}`);
      if (fragment) {
        this.jumpToSection(fragment);
      }
    });
  }

  jumpToSection(section: string) {
    setTimeout(() => {
      const element = document.getElementById(section);
      if (element) {
        setTimeout(() => {
          const offset = element.getBoundingClientRect().top + window.scrollY;
          window.scrollTo({
            top: offset,
            behavior: 'smooth'
          });
        }, 100);
      } else {
        console.log(`Element with id "${section}" not found`);
      }
    }, 1500);
  }

  onSubmit(): void {
    if (!this.contactForm.invalid) {
      this.submittedQuery = true;
      this.apiService.submitQuery(this.contactForm.value).subscribe(data => {
        this.contactForm.reset();
        this.contactForm.patchValue({ accountType: 'customer_query' });
        this.submittedQuery = true;
        this.setPersistentCookies(this.contactForm.value);
        this.apiService.saveCookieData(this.contactForm.value);
      });
    }
  }

  patchContactFormWithCookies(): void {
    if (this.cookieDataLoaded) {
      return;
    }

    const cookieKeys = this.cookieService.getAll();
    const excludedFields = ['address.orderType', 'address.addressType'];

    Object.keys(cookieKeys).forEach(key => {
      if (key.includes('.')) {
        const keyParts = key.split('.');
        let formGroup = this.contactForm;
        for (let i = 0; i < keyParts.length - 1; i++) {
          formGroup = formGroup.get(keyParts[i]) as FormGroup;
        }
        const fieldPath = keyParts.join('.');
        if (!excludedFields.includes(fieldPath)) {
          const cookieValue = cookieKeys[key];
          if (cookieValue && formGroup.get(keyParts[keyParts.length - 1])) {
            const control = formGroup.get(keyParts[keyParts.length - 1]);
            if (control && (!control.value || control.value === '')) {
              control.patchValue(cookieValue);
            }
          }
        }
      } else {
        if (!excludedFields.includes(key)) {
          const cookieValue = cookieKeys[key];
          if (cookieValue && this.contactForm.get(key)) {
            const control = this.contactForm.get(key);
            if (control && (!control.value || control.value === '')) {
              control.patchValue(cookieValue);
            }
          }
        }
      }
    });

    this.cookieDataLoaded = true;
  }

  private setPersistentCookies(formData: any) {
    const cookieMap = {};
    this.traverse(formData, '', cookieMap);

    const expirationDate = new Date();
    expirationDate.setFullYear(expirationDate.getFullYear() + 1);

    Object.entries(cookieMap).forEach(([key, value]) => {
      const valueAsString = typeof value === "number" && key !== 'id' ? value.toString() : value;
      if (typeof valueAsString === "string") {
        this.cookieService.set(key, valueAsString, expirationDate, undefined, undefined, true);
      }
    });
  }

  private traverse(data: any, prefix: string, cookieMap: any): void {
    for (const [key, value] of Object.entries(data)) {
      const newKey = prefix ? `${prefix}.${key}` : key;
      if (typeof value === 'object' && value !== null) {
        this.traverse(value, newKey, cookieMap);
      } else {
        cookieMap[newKey] = value;
      }
    }
  }
  private validatePhoneNumber(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      const phoneNumber = control.value;
      const phonePattern = /^(\+27|0|27)\d{9}$/;

      if (!phonePattern.test(phoneNumber)) {
        return { invalidPhoneNumber: true };
      }

      return null;
    };
  }

  defaultDownloadBrochure() {
    this.apiService.downloadFile('Connectivity_Solutions.pdf').subscribe();
  }
  downloadBrochure(sectionId: number) {
    const brochureUrl = this.sectionBrochureUrls[sectionId];
    if (brochureUrl) {
      const downloadLink = document.createElement('a');
      downloadLink.href = brochureUrl;
      downloadLink.download = 'brochure.pdf';
      downloadLink.click();
    } else {
      console.error('No brochure URL available for the selected section.');
    }
  }

  private fetchSectionData(pageId: number, sectionId: number) {
    this.apiService.getSectionById(pageId, sectionId).subscribe(
      (data: SectionData) => {
        this.sectionData[sectionId] = data;
        this.sectionBrochureUrls[sectionId] = data.brochureUrl;
        this.isDataLoadedWithoutError = true;
      },
      (error) => {
        console.error('Error fetching section data:', error);
        this.isDataLoadedWithoutError = false;
      }
    );

    this.apiService.getBannerImageData(pageId, sectionId).subscribe(
      (imageData: Blob) => {
        const objectURL = URL.createObjectURL(imageData);
        this.setBannerImageUrl(sectionId, objectURL);
      },
      (error) => {
        console.error('Error fetching banner image data:', error);
        this.isDataLoadedWithoutError = false;
      }
    );
  }

  private setBannerImageUrl(sectionId: number, objectURL: string) {
    const safeUrl = this.sanitizer.bypassSecurityTrustUrl(objectURL);
    this.sectionBannerImageUrls[sectionId] = safeUrl; // Use sectionId as the index
  }

  private validateName(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      const name = control.value ? control.value.trim() : '';
      const namePattern = /^(?!.*[-'\s]{2})[A-Za-z]+(?:[ '-][A-Za-z]+)*$/;

      if (!namePattern.test(name)) {
        return { invalidName: true };
      }

      return null;
    };
  }
  getErrorMessage(controlName: string): string {
    const control = this.contactForm.get(controlName);
    if (control?.hasError('required')) {
      return 'This field is required';
    }
    if (control?.hasError('email')) {
      return 'Please enter a valid email address';
    }
    if (control?.hasError('pattern')) {
      if (controlName === 'email') {
        return 'Please enter a valid email address';
      }
    }
    if (control?.hasError('invalidName')) {
      return 'Please enter a valid name (2-50 characters, letters, hyphens, and apostrophes only)';
    }
    if (control?.hasError('invalidPhoneNumber')) {
      return 'Please enter a valid South African phone number (e.g., 0123456789 or +27123456789)';
    }
    return '';
  }
}
