<ng-container *ngIf="isDataLoadedWithoutError; else defaultContent">
<!-- section 1 -->

<div class="section-1">
  <div class="section-1-img">
    <img *ngIf="sectionBannerImageUrls[1]" [src]="sectionBannerImageUrls[1]" alt="business internet connectivity solutions section 1 image">
  </div>
    <div class="dark-gradient">
        <div class="section-1-text">
            <h1 class="section-1-heading">{{ sectionData[1]?.headerTitle }}</h1>
            <p class="section-1-detail"> </p>
            <p class="section-1-detail"></p>
        </div>
    </div>
</div>


<!-- section 2 -->

<div class="section-2">
    <div class="section-2-img">
        <img src="sectionBannerImageUrls[2]" [src]="sectionBannerImageUrls[2]" alt="business internet solutions fibre to the business section image">
    </div>
    <div class="black-gradient">
    </div>
    <div class="fibre-detail-container">
        <div class="fibre-detail">
            <h1 class="fibre-detail-header">{{ sectionData[2]?.headerTitle }}</h1>
            <p class="fibre-detail-text">{{ sectionData[2]?.headerDescription }}
            </p>
            <div class="fibre-buttons">
                <div class="fibre-view">
                    <button class="fibre-btn" [routerLink]="['/business-internet-fibre']">View deals</button>
                </div>
                <div class="enterprise-view">
                    <button class="fibre-btn" [routerLink]="['/business-internet-fibre']">Enterprise fibre</button>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- businesses -->
<div class="businesses-container">
    <div class="businesses" style="text-align: center;">
        <img src="/assets/images/businesses.png" style="max-width: 100%;" alt="business internet connectivity fibre providers">
    </div>
</div>


<!-- section 3 -->

<div class="section-3 pic-gradient">
    <div class="section-3-img pic-gradient-img">
        <img src="sectionBannerImageUrls[3]" [src]="sectionBannerImageUrls[3]" alt="LTE & Sim section">
    </div>
    <div class="black-gradient"></div>
    <div class="fibre-detail-container">
        <div class="fibre-detail">
            <h1 class="fibre-detail-header">{{ sectionData[3]?.headerTitle }}</h1>
            <p class="fibre-detail-text">{{ sectionData[3]?.headerDescription }}
            </p>
            <button class="fibre-btn" routerLink="/business-internet-connectivity-solutions-lte-and-sim">View deals</button>
        </div>
    </div>
</div>

<!--  section 4 -->

<div class="section-4 pic-gradient">
    <div class="section-4-img pic-gradient-img">
        <img src="sectionBannerImageUrls[4]" [src]="sectionBannerImageUrls[4]" alt="wireless air">
    </div>
    <div class="black-gradient"></div>
    <div class="fibre-detail-container">
        <div class="fibre-detail">
          <h1 class="fibre-detail-header">{{ sectionData[4]?.headerTitle }}</h1>
          <p class="fibre-detail-text">{{ sectionData[4]?.headerDescription }}
          </p>
            <button class="fibre-btn" routerLink="/business-internet-connectivity-solutions-wireless-and-satellite">View deals</button>
        </div>
    </div>
</div>

<!-- section 5 -->

<div class="section-5 pic-gradient">
    <div class="section-5-img pic-gradient-img">
        <img src="sectionBannerImageUrls[5]" [src]="sectionBannerImageUrls[5]" alt="Satellite internet">
    </div>
    <div class="black-gradient"></div>
    <div class="fibre-detail-container section-5-container">
        <div class="fibre-detail">
          <h1 class="fibre-detail-header">{{ sectionData[5]?.headerTitle }}</h1>
          <p class="fibre-detail-text">{{ sectionData[5]?.headerDescription }}
            </p>
            <button class="fibre-btn" routerLink="/business-internet-connectivity-solutions-wireless-and-satellite">View deals</button>
        </div>
    </div>
</div>
</ng-container>

<ng-template #defaultContent>
  <!-- section 1 -->

  <div class="section-1">
    <div class="section-1-img">
      <img src="/assets/images/business-internet-connectivity-solutions.jpg" alt="business internet connectivity solutions section 1 image">
    </div>
    <div class="dark-gradient">
      <div class="section-1-text">
        <h1 class="section-1-heading">Business Solutions Connectivity</h1>
        <p class="section-1-detail"> </p>
        <p class="section-1-detail"></p>
      </div>
    </div>
  </div>


  <!-- section 2 -->

  <div class="section-2">
    <div class="section-2-img">
      <img src="/assets/images/smme-section-2.png" alt="business internet solutions fibre to the business section image">
    </div>
    <div class="black-gradient">
    </div>
    <div class="fibre-detail-container">
      <div class="fibre-detail">
        <h1 class="fibre-detail-header">Fibre to the Business</h1>
        <p class="fibre-detail-text">Fibre (also known as Fibre to the Business or FTTB) links your business to the
          Internet via <br>Fibre optic
          cables that use light impulses to carry data. These impulses bounce along <br>thin glass Fibre tubes at
          the speed of light, which makes Fibre very fast and stable, <br>resulting in a more reliable and
          buffer-free experience.”
        </p>
        <div class="fibre-buttons">
          <div class="fibre-view">
            <button class="fibre-btn" [routerLink]="['/business-internet-fibre']">View deals</button>
          </div>
          <div class="enterprise-view">
            <button class="fibre-btn" [routerLink]="['/business-internet-fibre']">Enterprise fibre</button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- businesses -->
  <div class="businesses-container">
    <div class="businesses" style="text-align: center;">
      <img src="/assets/images/businesses.png" style="max-width: 100%;" alt="business internet connectivity fibre providers">
    </div>
  </div>


  <!-- section 3 -->

  <div class="section-3 pic-gradient">
    <div class="section-3-img pic-gradient-img">
      <img src="/assets/images/smme-section-3.png" alt="LTE & Sim section">
    </div>
    <div class="black-gradient"></div>
    <div class="fibre-detail-container">
      <div class="fibre-detail">
        <h1 class="fibre-detail-header">LTE & Sim Only</h1>
        <p class="fibre-detail-text">LTE offers a faster and more efficient data network for small- and <br>medium-sized
          businesses, as well as for work-from-home employees, <br>with speeds up to three times faster than 3G.

        </p>
        <button class="fibre-btn" routerLink="/business-internet-connectivity-solutions-lte-and-sim">View deals</button>
      </div>
    </div>
  </div>

  <!--  section 4 -->

  <div class="section-4 pic-gradient">
    <div class="section-4-img pic-gradient-img">
      <img src="/assets/images/smme-section-4.png" alt="wireless air">
    </div>
    <div class="black-gradient"></div>
    <div class="fibre-detail-container">
      <div class="fibre-detail">
        <h1 class="fibre-detail-header">Wireless air</h1>
        <p class="fibre-detail-text">Business Internet Wireless Air provides high-speed, asymmetrical fibre- <br>like internet connection with speeds from 4Mbps to 30Mbps now with <br>NO Fair Usage Policy (monthly
          limitations), perfect for your small or <br>medium-sized business looking to mobilise its workforce.

        </p>
        <button class="fibre-btn" routerLink="/business-internet-connectivity-solutions-wireless-and-satellite" fragment="pricing">View deals</button>
      </div>
    </div>
  </div>

  <!-- section 5 -->

  <div class="section-5 pic-gradient">
    <div class="section-5-img pic-gradient-img">
      <img src="/assets/images/smme-section-5.png" alt="Satellite internet">
    </div>
    <div class="black-gradient"></div>
    <div class="fibre-detail-container section-5-container">
      <div class="fibre-detail">
        <h1 class="fibre-detail-header">Satellite internet</h1>
        <p class="fibre-detail-text">Satellite internet is wireless internet beamed down from satellites <br>orbiting
          the Earth. It’s a lot different from land-based internet services <br>like cable or DSL, which transmit
          data
          through wires. Since it’s the only <br>internet service that’s available nationwide, satellite internet
          is a
          reliable <br>way to get online for many rural homes and businesses, although it <br>does still come
          with a few
          disadvantages (more on that later).
        </p>
        <button class="fibre-btn" routerLink="/business-internet-connectivity-solutions-wireless-and-satellite" fragment="satellite-plans">View deals</button>
      </div>
    </div>
  </div>

</ng-template>
