import {Component, OnInit} from '@angular/core';
import {ModalService} from "../../services/modal.service";
import {Constants} from "../../constants/constants";
import {DomSanitizer, Meta, SafeUrl, Title} from "@angular/platform-browser";
import {MetaTags} from "../../constants/meta-data/meta.tags";
import {ApiService} from "../../services/api.service";
import {distinctUntilChanged, filter} from "rxjs";
import {ActivatedRoute, Router} from "@angular/router";

interface SectionData {
  sectionId: number;
  pageId: number;
  headerTitle: string;
  headerDescription: string;
  bannerUrl: string;
  brochureUrl: string;
}

interface BrochureData {
  pageId: number;
  sectionId: number;
  brochureUrl: string;
}

@Component({
  selector: 'app-wireless-and-satellite',
  templateUrl: './wireless-and-satellite.component.html',
  styleUrls: ['./wireless-and-satellite.component.scss']
})
export class WirelessAndSatelliteComponent implements OnInit{
  public lead: any;
  public sectionBannerImageUrls: SafeUrl[] = [];
  public sectionData: SectionData[] = [];
  public sectionBrochureUrls: string[] = [];
  public isDataLoadedWithoutError: boolean = false;

  constructor(
    public modalService: ModalService,
    private meta: Meta,
    private title: Title,
    private apiService: ApiService,
    private sanitizer: DomSanitizer,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) {

    this.meta.addTags(MetaTags.WIRELESS_AIR_AND_SATELLITE_TAGS);
    this.setTitle('Business Internet Wireless Air & Satellite | Apprentice Valley Digital');
    this.lead = {
      leadType: 'website',
      type: Constants.SATELLITE_AND_WIRELESS_AIR
    }
  }

  public setTitle(newTitle: string) {
    this.title.setTitle(newTitle);
  }

  ngOnInit(): void {
    const pageId = 6; // Replace with the actual pageId
    this.fetchSectionData(pageId, 1); // Section 1
    this.fetchSectionData(pageId, 2); // Section 2
    this.fetchSectionData(pageId, 3); // Section 3
    this.fetchSectionData(pageId, 4); // Section 3
    this.fetchSectionData(pageId, 5); // Section 3
    this.fetchSectionData(pageId, 6); // Section 3
    this.fetchSectionData(pageId, 7); // Section 3

    this.subscribeToFragment();
  }

  subscribeToFragment() {
    this.activatedRoute.fragment.pipe(
      filter(fragment => !!fragment), // Only proceed if fragment is not null or undefined
      distinctUntilChanged() // Only react if the fragment has changed
    ).subscribe(fragment => {
      console.log(`Attempting to scroll to fragment: ${fragment}`);
      if (fragment) {
        this.jumpToSection(fragment);
      }
    });
  }

  jumpToSection(section: string) {
    setTimeout(() => {
      const element = document.getElementById(section);
      if (element) {
        setTimeout(() => {
          const offset = element.getBoundingClientRect().top + window.scrollY;
          window.scrollTo({
            top: offset,
            behavior: 'smooth'
          });
        }, 100);
      } else {
        console.log(`Element with id "${section}" not found`);
      }
    }, 1000);
  }

  defaultDownloadBrochure() {
    this.apiService.downloadFile('Connectivity_Solutions.pdf').subscribe();
  }

  downloadBrochure(sectionId: number) {
    const brochureUrl = this.sectionBrochureUrls[sectionId];
    if (brochureUrl) {
      const downloadLink = document.createElement('a');
      downloadLink.href = brochureUrl;
      downloadLink.download = 'brochure.pdf';
      downloadLink.click();
    } else {
      console.error('No brochure URL available for the selected section.');
    }
  }

  private fetchSectionData(pageId: number, sectionId: number) {
    this.apiService.getSectionById(pageId, sectionId).subscribe(
      (data: SectionData) => {
        this.sectionData[sectionId] = data; // Use sectionId as the index
        this.sectionBrochureUrls[sectionId] = data.brochureUrl; // Use sectionId as the index
        this.isDataLoadedWithoutError = true;
      },
      (error) => {
        console.error('Error fetching section data:', error);
        this.isDataLoadedWithoutError = false;
      }
    );
    this.apiService.getBannerImageData(pageId, sectionId).subscribe(
      (imageData: Blob) => {
        const objectURL = URL.createObjectURL(imageData);
        this.setBannerImageUrl(sectionId, objectURL);
      },
      (error) => {
        console.error('Error fetching banner image data:', error);
        this.isDataLoadedWithoutError = false;
      }
    );
  }

  private setBannerImageUrl(sectionId: number, objectURL: string) {
    this.sectionBannerImageUrls[sectionId] = this.sanitizer.bypassSecurityTrustUrl(objectURL); // Use sectionId as the index
  }

  openContactPage() {
    this.router.navigate(['/home'], { fragment: 'contact' });
  }
}
